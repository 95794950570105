<template>
  <div id="interview" v-cloak>
    <div
      class="container main-container-grey"
      style="max-width: 450px"
      v-if="env != undefined"
    >
      <div class="ep-recording-body">
        <div v-if="current_view == 'browser_support'">
          <div class="row">
            <div class="col-md-12 text-center">
              Please ensure you are using the latest version of the Chrome browser to access this feature.
            </div>
          </div>
        </div>

        <!-- LANDING PAGE -->
        <div v-if="current_view == 'landing'">
          <div
            class="row"
            v-if="highFives && !selectedStudent && !showFilters"
            style="margin-top: -18px"
          >
            <div class="col-xs-12">
              <div class="text-center" style="font-style: italic">
                Please note that this video will go to all students who gave you
                High Fives.
              </div>
              <h4>Tips on recording your High Five Video</h4>
              <ul>
                <li>Try not to read off a script.</li>
                <li>
                  Don’t say, “Hi, everyone!“. Make the students feel
                  individually seen and heard.
                </li>
                <li>
                  You'll be able to include text along with your video, so don't
                  take the time to spell out URLs on camera.
                </li>
                <li>Include some school spirit in your background!</li>
              </ul>
              <video
                v-if="platform == 'epic'"
                :src="
                  env.staticAddr +
                  '/shield/mercury/file/b5400c3f646823eaddf2347425c4d695/'
                "
                height="auto"
                width="100%"
                playsinline
                controls
              ></video>
            </div>
            <div class="col-xs-12">
              <h4>Sample High Five Video</h4>
              <video
                v-if="platform == 'epic'"
                :src="
                  env.staticAddr +
                  '/shield/mercury/file/6b53c10cd2b36dce1a74bae510a679e7/'
                "
                height="auto"
                width="100%"
                playsinline
                controls
              ></video>
              <video
                v-else-if="platform == 'tuesday_talks'"
                :src="
                  env.staticAddr +
                  '/shield/mercury/file/34848a5765360c646f8bf224ba16da54/'
                "
                height="auto"
                width="100%"
                playsinline
                controls
              ></video>
            </div>
          </div>
          <div v-else>
            <h4>Tips on recording your Video Response</h4>
            <ul>
              <li>Try not to read off a script.</li>
              <li v-if="!selectedStudent">
                Don’t say, “Hi, everyone!“. Make the students feel individually
                seen and heard.
              </li>
              <li>
                You'll be able to include text along with your video, so don't
                take the time to spell out URLs on camera.
              </li>
              <li>Include some school spirit in your background!</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <button
                class="btn btn-massive-grey"
                v-on:click="goNext('check')"
              >
                <img :src="env.staticAddr + '/ivgcs/img/epic-play-icon.png'" />
                <div>
                  <b>Click here to Start Recording</b>
                </div>
              </button>
            </div>
          </div>
        </div>

        <!-- RECORDING AND REVIEW -->

        <div class="row" v-show="current_view == 'recording'">
          <div class="col-md-12 text-center">
            <div class="ep-sub-header">Recording...</div>
          </div>
        </div>
        <div class="row" v-show="current_view == 'ready'">
          <div class="col-md-12">
            <transition name="update" mode="out-in">
              <div class="ep-sub-header text-center">
                Looking good! Ready to begin?
              </div>
            </transition>
          </div>
        </div>

        <!-- Audio Alert -->
        <div class="row" v-show="(current_view == 'ready' || current_view == 'recording') && audioTracker.working == false">
          <div class="col-md-12 text-center">
              <div class="panel">
                <div class="panel-body bg-danger text-danger">
                  Oops, we're not getting sound from your microphone! Make sure you are talking loudly enough. If this message doesn't go away, try selecting a different microphone below.
                </div>
              </div>
          </div>
          <div class="col-md-12 text-center">
            Select Microphone:
            <select class="form-control" v-model='microphoneSelection.deviceId' v-on:change="micSelection()">
                <option v-for="devices in inputDevices" :value="devices.deviceId">{{devices.label}}</option>
            </select>
          </div>
        </div>

        <!-- viewfinder -->
        <div
          class="row"
          style="margin-bottom: 0px"
          v-show="
            current_view == 'check' ||
            current_view == 'ready' ||
            current_view == 'recording'
          "
        >
          <div class="col-md-12">
            <div style="position: relative" id="viewfinder-wrapper">
              <div
                style="
                  position: absolute;
                  right: 3px;
                  top: 3px;
                  padding: 15px;
                  background-color: #fff;
                  opacity: 0.85;
                  z-index: 1;
                  font-size: 16px;
                "
                v-bind:style="{ color: time > 15 ? '#333' : '#f00' }"
              >
                {{ computedTime }}
              </div>
            </div>
            <video
              v-if="showViewfinder"
              autoplay="true"
              id="viewfinder"
              playsinline
              style="width: 100%"
              muted
            ></video>
          </div>
        </div>

        <!-- button -->
        <div style="position: relative; text-align: center">
          <div style="position: absolute; bottom: 40px; width: 100%">
            <div v-show="current_view == 'check'">
              <span
                class="btn btn-default btn-flashorange"
                v-on:click="startCamera()"
              >
                Turn on Camera
              </span>
            </div>
            <div v-show="current_view == 'ready'">
              <span
                class="btn btn-default btn-transparent btn-flashorange"
                v-on:click="startRecording()"
              >
                Start Recording
              </span>
            </div>
            <div v-show="current_view == 'recording'">
              <span
                class="btn btn-default btn-transparent"
                v-on:click="endRecording()"
              >
                Click here when done
              </span>
            </div>
          </div>
        </div>

        <!-- Microphone Selection Tool -->
        <div class="row" v-show="(current_view == 'ready' || current_view == 'recording') && audioTracker.working == true && selectionToolLock == true">
              <div class="col-md-12 text-center">
                  Microphone options:
                  <select class="form-control" v-model='microphoneSelection.deviceId' v-on:change="micSelection()">
                      <option v-for="devices in inputDevices" :value="devices.deviceId">{{devices.label}}</option>
                  </select>
              </div>
        </div>

        <!-- playback -->
        <div
          v-show="
            current_view == 'review' ||
            current_view == 'school' ||
            current_view == 'send'
          "
        >
          <div class="row">
            <div class="col-xs-12">
              <div class="videoWrapper">
                <video
                  v-if="showPlayback"
                  class="video-js vjs-default-skin"
                  id="playback"
                  width="480px"
                  height="268px"
                  preload="none"
                  src="https://initialview.com/ivgcs/video/blank.mp4"
                  type="video/mp4"
                  playsinline
                  webkit-playsinline="webkit-playsinline"
                />
              </div>
            </div>
          </div>

          <div>
            <div class="row">
              <div class="col-xs-12 text-center">
                <button
                  v-show="!recordAgainMessage"
                  class="btn btn-default"
                  v-on:click="recordAgainMessage = true"
                >
                  Delete and Record Again
                </button>

                <div v-show="recordAgainMessage">
                  <div class="ep-header">Are you sure?</div>
                  <div class="ep-sub-header">
                    You're about to delete what you recorded and start over.
                  </div>
                  <div class="row">
                    <div class="col-xs-12 text-center">
                      <button
                        class="btn btn-default"
                        v-on:click="confirmRecordAgain()"
                      >
                        That's fine; I need a do-over
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12 text-center">
                      <button
                        class="btn btn-orange"
                        v-on:click="recordAgainMessage = false"
                      >
                        Wait, I want to keep what I've got
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 text-center">
                <textarea
                  class="form-control"
                  placeholder="Thank you for your interest in our school! Please visit our school’s site here (www.infosession.com) to learn more about our upcoming virtual events!"
                  style="width: 100%; height: 200px; resize: vertical"
                  v-model="textMessage"
                ></textarea>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-5 text-right">
                <p style="color:grey;">Delete video in 14 days</p>
              </div>
              <div class="col-xs-2 text-center" style="padding: 0;">
                <!-- Rounded switch -->
                <label class="switch">
                  <input type="checkbox" v-model="preserveVideo">
                  <span class="switchSlider round"></span>
                </label>
              </div>
              <div class="col-xs-5 text-left">
                <p style="color:#e96724;">Preserve the video</p>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 text-center">
                * This response video will automatically be removed from the student’s account after 14 days. It will also be deleted on your side after 14 days. If you instead prefer to preserve access to the response video in your InitialView account, switch the toggle.
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 text-center">
                <div style="padding: 10px">
                  <b>Ready to send your video?</b>
                </div>
                <div
                  v-if="
                    platform == 'tuesday_talks' &&
                    !selectedStudent &&
                    showFilters
                  "
                >
                  <button
                    v-show="videoFileUid"
                    class="btn btn-orange"
                    v-on:click="goNext('save_video_response')"
                  >
                    Next
                  </button>
                  <button
                    v-show="!videoFileUid"
                    v-bind:disabled="!videoFileUid"
                    class="btn btn-orange"
                  >
                    Uploading...
                  </button>
                </div>
                <div v-else>
                  <!-- for EPIC, or generic/individual from TT -->
                  <button
                    v-show="videoFileUid"
                    class="btn btn-orange"
                    v-on:click="sendToStudent()"
                  >
                    Send to {{ studentName || " your High Fives" }}
                  </button>
                  <button
                    v-show="!videoFileUid"
                    v-bind:disabled="!videoFileUid"
                    class="btn btn-orange"
                  >
                    Uploading...
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- TT only: SAVE VIDEO RESPONSE? -->
        <div v-if="current_view == 'save_video_response'">
          <div class="row">
            <div class="col-xs-12">
              <h4>
                Would you like to save this video response to send to other
                students?
              </h4>
              <div>
                If so, just type in a label (students won't see this label -
                it's just for your reference)
              </div>
              <div class="form-inline">
                <label>Label:</label>
                <input class="form-control" v-model="label" />
              </div>
              <hr />
              <button
                class="btn btn-orange pull-left"
                v-on:click="sendToStudent(false, false)"
              >
                No need to save this video
              </button>
              <button
                class="btn btn-orange pull-right"
                :disabled="!label"
                v-on:click="goNext('future_sends')"
              >
                Save!
              </button>
            </div>
          </div>
        </div>

        <div v-if="current_view == 'future_sends'">
          <div class="row">
            <div class="col-xs-12">
              <div v-if="highFives">
                <h4>
                  Would you like us to automatically send this video response to
                  new High Fives?
                </h4>
                <div>
                  More students will give you High Fives in the coming months!
                  Would you like us to automatically send this video response to
                  new High Five students who meet the criteria you selected?
                </div>
              </div>
              <div v-else>
                <h4>
                  Would you like us to automatically send this video response to
                  new Tuesday Talks registrants?
                </h4>
                <div>
                  More students will register for Tuesday Talks in the coming
                  months! Would you like us to automatically send this video
                  response to new registrants who meet the criteria you
                  selected?
                </div>
              </div>
              <div
                class="row filter-labels"
                v-show="showFilters"
                style="margin-left: 0px; margin-right: 0px"
              >
                <span style="font-weight: bold">Filters: </span>
                <!-- Could probably use a for loop here or something... -->
                <div
                  class="label label-default"
                  v-for="grade in filters.grades"
                  v-bind:key="grade.label"
                  v-show="
                    !Object.values(filters.grades).every(
                      (grade) => grade.show
                    ) && grade.show
                  "
                >
                  Grade: {{ grade.label }}
                </div>
                <div
                  class="label label-default"
                  v-for="interest in filters.academicInterests"
                  v-bind:key="interest.label"
                  v-show="
                    !Object.values(filters.academicInterests).every(
                      (interest) => interest.show
                    ) && interest.show
                  "
                >
                  Interest: {{ interest.label }}
                </div>
                <div
                  class="label label-default"
                  v-for="size in filters.collegeSize"
                  v-bind:key="size.label"
                  v-show="
                    !Object.values(filters.collegeSize).every(
                      (size) => size.show
                    ) && size.show
                  "
                >
                  Size: {{ size.label }}
                </div>
                <div
                  class="label label-default"
                  v-for="region in filters.geographicPreference"
                  v-bind:key="region.label"
                  v-show="
                    !Object.values(filters.geographicPreference).every(
                      (region) => region.show
                    ) && region.show
                  "
                >
                  Region: {{ region.label }}
                </div>
                <div
                  class="label label-default"
                  v-for="gender in filters.genders"
                  v-bind:key="gender.label"
                  v-show="
                    !Object.values(filters.genders).every(
                      (gender) => gender.show
                    ) && gender.show
                  "
                >
                  Gender: {{ gender.label }}
                </div>
                <div
                  class="label label-default"
                  v-for="entryDate in filters.entryDates"
                  v-bind:key="entryDate.label"
                  v-show="
                    !Object.values(filters.entryDates).every(
                      (entryDate) => entryDate.show
                    ) && entryDate.show
                  "
                >
                  Entry Date: {{ entryDate.label }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6">
              <button
                class="btn btn-orange pull-left"
                v-on:click="sendToStudent(true, false)"
              >
                No - just save my response and I’ll manually send responses
                later
              </button>
            </div>
            <div class="col-xs-6">
              <button
                class="btn btn-orange pull-right"
                v-on:click="sendToStudent(true, true)"
              >
                Yes - please save me time and automatically send this video
                response in the future!
              </button>
            </div>
          </div>
        </div>

        <!-- SEND -->
        <div v-show="current_view == 'sent'">
          <div class="row"></div>
          <div v-if="platform == 'epic'">
            <div class="text-center" v-show="!(highFives && studentName)">
              <img
                id="unlocked-svg"
                class="reveal"
                width="300px"
                src="../assets/unlocked.svg"
              />
            </div>
            <div style="padding: 40px 50px">
              <div class="text-center">
                <div
                  style="color: #409a6f"
                  v-show="!(highFives && studentName)"
                >
                  <b>Student Information Unlocked!</b>
                </div>
                <div style="padding-bottom: 20px">
                  <b>
                    Your video message has been sent to
                    {{ studentName || " your High Fives" }}.</b
                  >
                </div>
                <button
                  type="button"
                  class="btn btn-orange"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  {{ highFives ? "Back to Student List" : "Unlock more love!" }}
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <div style="padding: 40px 50px">
              <div class="text-center">
                <div style="color: #409a6f">
                  <b>All set!</b>
                </div>
                <div style="padding-bottom: 20px">
                  <p
                    v-if="!selectedStudent && showFilters"
                    class="modal-title"
                    style="font-weight: bold"
                  >
                    Your video message has sent to the selected students!
                  </p>
                  <p v-else class="modal-title" style="font-weight: bold">
                    Your video message has been sent to
                    {{ studentName ? studentName : " your High Fives" }}!
                  </p>
                  <p style="margin-top: 20px; font-style: italic">
                    {{ success_message }}
                  </p>
                </div>
                <button
                  type="button"
                  class="btn btn-orange"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Back to Student List
                </button>
              </div>
            </div>
          </div>
          <div class="row" v-show="showUploadWarning">
            <div
              class="col-xs-12 text-center"
              v-show="
                originalVideoUploadProgress && originalVideoUploadProgress < 100
              "
            >
              <div>
                Your video is still uploading, please do not close this tab
                until the upload is complete!
              </div>
              <div v-show="originalVideoUploadProgress">
                {{ originalVideoUploadProgress }}%
              </div>
            </div>
            <div
              class="col-xs-12 text-center"
              v-show="
                originalVideoUploadProgress &&
                originalVideoUploadProgress == 100
              "
            >
              Your video is finished uploading, you may now close this tab!
            </div>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
                aria-label="Close"
              >
                Unlock more love!
              </button>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          tabindex="-1"
          role="dialog"
          id="record-again-modal"
        >
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-body text-center">
                <div class="ep-header">Are you sure?</div>
                <div class="ep-sub-header">
                  You're about to delete what you recorded and start over.
                </div>
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <button
                      class="btn btn-default"
                      v-on:click="confirmRecordAgain()"
                    >
                      That's fine; I need a do-over
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <button
                      class="btn btn-orange"
                      v-on:click="closeModal('record-again-modal')"
                    >
                      Wait, I want to keep what I've got
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          tabindex="-1"
          role="dialog"
          id="record-failed-modal"
        >
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-body text-center">
                <div>
                  It looks like the internet connection is unstable - please try
                  recording again using another connection!
                </div>
                <div class="row">
                  <div class="col-xs-12">
                    <button
                      class="btn btn-orange"
                      v-on:click="resetFailedRecording()"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { ivRecordClientMixin } from "../mixins/ep-record-mixin-pack.js";
import { epSupportMixin } from "../mixins/ep-support-mixin-pack.js";
import { ivTwilioWebrtcMixin } from "../mixins/twilio-webrtc-mixin.js";

import {
  ivModalMixin,
  ivAlertMixin,
  ivSpinnerMixin,
  getParameterByName,
  ivDiagnosticsMixin,
} from "@/mixins/base-components-pack.js";

import ivbus from "@/utils/ivbus.js";
import env from "../utils/env.js";
import apiClient from "../utils/apiClient.js";
import moment from "moment-timezone";

export default {
  mixins: [
    ivModalMixin,
    ivAlertMixin,
    ivSpinnerMixin,
    ivRecordClientMixin,
    epSupportMixin,
    ivTwilioWebrtcMixin,
    ivDiagnosticsMixin,
  ],

  props: {
    highFives: {
      type: Boolean,
      required: true,
    },
    selectedStudent: {
      type: Object,
      default: null,
    },
    platform: {
      type: String,
      default: "epic",
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  
  computed: {
    computedTime () {
      var that = this;
      return Math.max(that.time, 0);
    }
  },

  data() {
    return {
      textMessage: "",
      videoFileUid: "",
      preserveVideo: false,
      studentName: "",
      label: "",
      future_sends: false,
      audioTracker: {working: null, status: 0, level: 0},
      inputDevices: [],
      microphoneSelection: {deviceId: "default"},
      selectionToolLock: false,
      onlyOnce: true,

      success_message: "",

      env: env,
      current_view: "landing",
      pre_record: true,
      isSafari: false,
      isChrome: false,
      playbackLoading: false,
      showViewfinder: true,
      showPlayback: true,

      recordAgainMessage: false,

      time: 30,
      questionTimer: null,

      uiMessageWaitShort: 5000,
      uiMessageWaitLong: 10000,

      upload_token: "",
      browser_support_message: "",

      videoProvider: "twilio",
      twilio_room_identifier: "",
      twilio_room_sid: "",

      camera: null,
      vid_currently_playing: "",

      showUploadWarning: false,
    };
  },

  mounted: function () {
    var that = this;

    that.findDevices();
    ivbus.$on("setEpicCurrentView", function (val) {
      if (that.current_view == "browser_support") {
        return;
      }
      that.current_view = val;
      that.label = "";
      that.future_sends = false;
    });

    ivbus.$on("videoRecordingUploadComplete", function (video_file_uid) {
      that.videoFileUid = video_file_uid;
    });

    var isIos = function () {
      return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    };

    if (isIos()) {
      that.browser_support_message = "ios";
      that.goNext("browser_support");
      return;
    }

    that.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (that.isSafari) {
      that.browser_support_message = "safari";
      that.goNext("browser_support");
      return;
    }

    that.isChrome =
      /^.+(AppleWebKit\/)+((?!\/).)+(chrome\/).+(safari\/)[^\s]+$/i.test(
        navigator.userAgent
      );

    if (!that.isChrome) {
      that.browser_support_message = "notchrome"; // but allow them to continue, I suppose
      that.goNext("browser_support");
      return;
    }

  },

  methods: {

    sendToStudent: async function (
      save_response = false,
      future_sends = false
    ) {
      var that = this;
      console.log("Sending to student");
      that.showPlayback = false;
      var spinId = that.spinStart();

      if (!save_response) {
        that.label = null;
      }

      if (future_sends) {
        that.future_sends = true;
      }

      var retries = 0;

      var submitResponse = async function () {
        retries += 1;

        if (that.platform == "epic") {
          try {
            var response = await apiClient.post(
              "/api/org/epic/respond/?s=" +
                (that.selectedStudent ? that.selectedStudent.send_id : ""),
              {
                retry: retries,
                send_id: that.selectedStudent
                  ? that.selectedStudent.send_id
                  : null,
                video: that.videoFileUid,
                message: that.textMessage,
                delete: !that.preserveVideo
              }
            );
            if (response.data.status == 200) {
              that.textMessage = "";
            }
          } catch (err) {
            that.showMessage(
              "Problem contacting server, please retry.",
              "alert-danger",
              that.uiMessageWaitShort
            );
            that.spinStop(spinId);
            return;
          }
        } else if (that.platform == "tuesday_talks") {
          try {
            var response = await apiClient.post(
              "/api/org/tt/respond/?s=" +
                (that.selectedStudent ? that.selectedStudent.send_id : ""),
              {
                retry: retries,
                send_id: that.selectedStudent
                  ? that.selectedStudent.send_id
                  : null,
                video: that.videoFileUid,
                message: that.textMessage,
                delete: !that.preserveVideo,
                label: that.label,
                future_sends: that.future_sends,
                showFilters: that.showFilters,
                filters: that.filters,
                highFives: that.highFives,
              }
            );
            if (response.data.status == 200) {
              that.success_message = "";
              if (that.label) {
                that.success_message =
                  "Your video response has also been saved and is viewable in the 'My Video Responses' tab.";
              }
              if (that.future_sends) {
                that.success_message =
                  that.success_message +
                  " This response will be sent to " +
                  (that.highFives ? "future High Fives." : "new registrants.");
              }
              that.textMessage = "";
              that.label = "";
              that.future_sends = false;
            }
          } catch (err) {
            console.log(err);
            that.showMessage(
              "Problem contacting server, please retry.",
              "alert-danger",
              that.uiMessageWaitShort
            );
            that.spinStop(spinId);
            return;
          }
        }

        if (response.data.status == 200) {
          that.spinStop(spinId);
          ivbus.$emit("refreshStudents");
          try {
            // if we don't try-catch this, people who refresh the page at this point get stuck when this errors out.
            that.camera.getTracks().forEach(function (track) {
              track.stop();
            });
            that.twilioLeave();
          } catch {
            console.debug("no camera to stop and/or no twilio");
          }
          that.goNext("sent");
          document.querySelector("#unlocked-svg").classList.remove("revealNow");
          document.querySelector("#unlocked-svg").classList.add("revealNow");
        } else {
          if (retries > 5) {
            that.spinStop(spinId);
            that.showMessage(
              "Server response error, please contact support!",
              "alert-danger",
              that.uiMessageWaitShort
            );
          } else {
            setTimeout(submitResponse, 1000 * Math.pow(retries, 1.5));
          }
        }
      };
      setTimeout(submitResponse, 2000);
    },

    startCamera: function () {
      var that = this;
      // so here we set up viewfinder
      that.pre_record = false;
      that.videoFileUid = "";
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: { width: 640, height: 360 },
        })
        .then(function (camera) {
          that.camera = camera;
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();
          const microphone = audioContext.createMediaStreamSource(camera);
          const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

          analyser.smoothingTimeConstant = 0.8;
          analyser.fftSize = 1024;

          microphone.connect(analyser);
          analyser.connect(scriptProcessor);
          scriptProcessor.connect(audioContext.destination);
          scriptProcessor.onaudioprocess = function() {
              const array = new Uint8Array(analyser.frequencyBinCount);
              analyser.getByteFrequencyData(array);
              const arraySum = array.reduce((a, value) => a + value, 0);
              const average = arraySum / array.length;
              that.audioMonitor(average);
          };
          console.log(camera);
          $("#viewfinder")[0].srcObject = that.camera;
          that.joinInterviewRoom();
          that.goNext("ready");
        })
        .catch(function (error) {
          alert(
            "Unable to capture your camera. Please ensure you are using the Chrome browser on your device."
          );
          console.error(error);
        });
    },

      audioMonitor: function(vol) {
        var that = this;
            
        that.audioTracker.status++;
        that.audioTracker.level = Math.round(vol / 10);
        if (that.audioTracker.level > 0) {
          that.audioTracker.working = true;
          //console.log("audio working")
        }
        if (that.audioTracker.status == 300) {
          if (that.audioTracker.working == null) {
            that.selectionToolLock = true;
            that.audioTracker.working = false;
          }
          if (that.audioTracker.working == false) {
            that.audioTracker.status = 0;
            that.selectionToolLock = true;
          }
        }
        if (that.audioTracker.status == 50) {
          if (that.audioTracker.working == false) {
            that.audioTracker.status = 0;
            that.selectionToolLock = true;
          }
          if (that.audioTracker.working == true) {
            //console.log('Audio is working')
            if (that.selectionToolLock == true && that.onlyOnce == true) {
              that.showMessage(
                "Microphone is working now!",
                "alert-success",
                5000
              );
              that.onlyOnce = false;
            }
            that.audioTracker.status = 0;
          }
        }
      },

    findDevices: function() {
        var that = this;
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
            return;
        }

        navigator.mediaDevices.enumerateDevices()
        .then(function(devices) {
            devices.forEach(function(device) {
            // console.log(device.kind + ": " + device.label +
            // " id = " + device.deviceId);
            if (device.kind == 'audioinput'){
                that.inputDevices.push(device)
            }
            });
        })
        .catch(function(err) {
            console.log(err.name + ": " + err.message);
        });
        console.log(that.inputDevices)
    },

    micSelection: function() {
        var that = this;
        console.log(that.microphoneSelection.deviceId);
        console.log(that.camera);
        navigator.mediaDevices.getUserMedia({
            audio: {
                deviceId: that.microphoneSelection.deviceId
            },
            video: {
                facingMode: "user"
            }
        }).then(function (camera) {
            that.camera = camera;
            const audioContext = new AudioContext();
            const analyser = audioContext.createAnalyser();
            const microphone = audioContext.createMediaStreamSource(camera);
            const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(scriptProcessor);
            scriptProcessor.connect(audioContext.destination);
            scriptProcessor.onaudioprocess = function() {
                const array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                const arraySum = array.reduce((a, value) => a + value, 0);
                const average = arraySum / array.length;
                that.audioMonitor(average);
            };
            $("#viewfinder")[0].srcObject = camera;
        }).catch(function (error) {
            alert('Unable to capture your camera. Please ensure you are using the Chrome browser on your device.');
            console.error(error);
        });
      },

    joinInterviewRoom: function () {
      // this function is simply for creating a backup twilio room recording for this EP
      var that = this;
      var url = "";
      if (that.platform == "epic") {
        url = "get_ep_amelia_room_token";
      } else if (that.platform == "tuesday_talks") {
        url = "get_tt_amelia_room_token";
      }
      if (this.videoProvider == "twilio") {
        // this will pass apitoken (browser_token or other) to get_xx_amelia_room_token
        apiClient
          .get("/api/twiliovideo/" + url, {
            params: {
              send_id: that.selectedStudent ? that.selectedStudent.send_id : "",
              filtered: !!that.filters.grades,
            },
          })
          .then(function (response) {
            console.debug(
              "ivee: " +
                url +
                response.data.status +
                " " +
                response.data.message
            );
            if (response.data.video_room) {
              console.debug("room token: " + response.data.video_room);
              that.twilio_room_identifier = response.data.video_room;
              that.twilio_room_sid = response.data.room_sid;
            }
            if (response.data.status == 200) {
              that.twilioJoin(response.data, that.camera);
            } else {
              console.debug("IVER fetch token status != 200");
            }
          })
          .catch(function (error) {
            console.error(error.stack);
            that.showMessage(
              "Unable to join recording server.",
              "alert-danger"
            );
            console.error(error);
            // that.sessionAppend({
            //   action: "IIAB2_JOIN_ROOM_ERROR_" + that.$route.params.bookingid,
            //   error: error.stack
            // });
            // that.sessionSend();
          });
      } // end twilio function
    },
    startRecording: async function () {
      var that = this;
      var decrementTime = function () {
        that.time -= 1;
        if (that.time == -2) {
          that.endRecording();
        } else {
          that.questionTimer = setTimeout(decrementTime, 1000);
        }
      };
      that.studentName = await ivbus.getFlag("student_name");
      that.recordingVideoStart(that.camera);
      that.goNext("recording", true);
      that.questionTimer = setTimeout(decrementTime, 1000);
    },
    endRecording: function () {
      var that = this;
      if (that.time > 27) {
          return; // make Done unclickable for 3 seconds to prevent double clicking
      }
      that.playbackLoading = true;
      clearTimeout(that.questionTimer);
      that.time = 30;
      that.recordingVideoStop(function () {
        that.previewRecording();
        that.twilioLeave();
        that.camera.getTracks().forEach(function (track) {
          track.stop();
        });
        that.videoFileUid = ""; // reset videoFileUid when recording ends, in case a previous recording finished uploading while the new recording was in progress. 
        that.uploadVideoRecording(); // eager upload
        that.goNext("review");
        //STP: For testing the svg fade in. I couldn't reach the 'sent' view...
        //~ document.querySelector('#unlocked-svg2').classList.remove('revealNow')
        //~ document.querySelector('#unlocked-svg2').classList.add('revealNow')
      });
    },
    resetFailedRecording: function () {
      var that = this;
      this.closeModal("record-failed-modal");
      this.showViewfinder = true;
      setTimeout(function () {
        that.goNext("check");
        that.startCamera();
      });
    },
    recordAgain: function () {
      that.recordAgainMessage = true;
    },
    confirmRecordAgain: function () {
      var that = this;
      that.recordAgainMessage = false;
      that.myPlayer.reset();
      $("#my-player").remove();
      setTimeout(function () {
        that.goNext("check");
        that.startCamera();
      });
    },

    goNext: function (next) {
      var that = this;
      var goHere = next;
      setTimeout(function () {
        $("html, body").animate({
          scrollTop: $("body").offset().top,
        });
        that.current_view = goHere;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container-grey {
  background-color: #f3f0f1;
}

.filter-labels > .label-default {
  display: inline-block;
}

.label-default {
  background: #fff;
  color: #333;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-massive-grey {
  width: 300px;
  height: 200px;
  background-color: #e6e6e6;
  color: $orange;
  border-radius: 7px;
}
.orange-bar {
  background: $orange;
  color: #fff;
  padding: 7px;
}
.epic-school-logo {
  max-width: 100%;
  padding: 5px;
  max-height: 50px;
  text-align: center;
}
.epic-school-logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px;
}
.epic-school-video > video {
  width: 80%;
  padding: 5px;
  max-height: 120px;
}
@keyframes flashorange {
  from {
    border-color: #ccc;
  }
  to {
    border-color: #e96724;
  }
}
.ep-recording-header {
  display: flex;
  justify-content: space-between;
  text-align: right;
  padding: 15px;
  margin-top: 15px;
  width: 100%;
  color: #000;
  background-color: #f2f0f1;
  height: 80px;
}
.epic-header-email {
  font-size: 1em;
  background: $orange;
  padding: 3px 12px;
  border-radius: 8px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
}
.ep-recording-body {
  width: 100%;
  margin-bottom: 15px;
  background-color: #f3f0f1;
  padding: 10px;
  margin: 0;
  border: none;
}
.ep-header {
  font-size: 24px;
  color: #e96724;
  margin-top: -5px;
}
.ep-sub-header {
  font-size: 16px;
  margin: 10px 0 10px 0;
}
.ep-orange-bold-font {
  color: #e96724;
  font-weight: bold;
}
.btn.btn-flashorange {
  border-width: 5px;
  animation-duration: 1s;
  animation-name: flashorange;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.alert {
  background-color: rgb(247, 155, 103);
  color: white;
}
.ep-validation {
  color: red;
  font-size: 12px;
}
audio {
  display: none;
}
/*.video-js .vjs-control-bar {
    display:none;
  }
  */
.video-js .vjs-live-control {
  display: none;
}
/* */
.well.well-ep {
  /*background-color: #fcfcfc;*/
  background-color: white;
  border: 1px solid #e96724;
  border-radius: 0px;
  margin-bottom: 0px;
}
.panel-ep {
  border: solid #e96724 1px;
  border-radius: 15px;
  margin-top: -17px;
}
.btn-transparent {
  opacity: 0.7;
}
.vjs-tech {
  transform: scale(-1, 1);
}
.pg-header {
  text-align: center;
  padding: 25px 0px 15px;
  font-weight: bold;
  font-size: 1.3em;
}
.play-btn {
  font-size: 3em;
  color: rgb(233, 103, 36);
  position: absolute;
  top: 30px;
  left: 25px;
}
.video-card {
  background-color: #fff;
  width: calc(50% - 10px);
  margin: 5px;
  padding: 0px 0px 7px;
}
.video-deck {
  background-color: #f3f0f1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.blocking {
  opacity: 0;
}
.unblocking {
  opacity: 1;
}
.reveal {
  opacity: 0;
  position: relative;
}
.reveal.revealNow {
  animation: revealNowNotLater 2s;
  opacity: 1;
}
@keyframes revealNowNotLater {
  from {
    opacity: 0;
    transform: scale(0.2);
    top: 75px; /* for slide in effect */
    top: 0px; /* slide disabled */
  }
  to {
    opacity: 1;
    transform: scale(1);
    top: 0;
  }
}

  /* rounded switch */

      /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .switchSlider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height:34px;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .switchSlider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .switchSlider {
      background-color: #e96724;
    }

    input:focus + .switchSlider {
      box-shadow: 0 0 1px #e96724;
    }

    input:checked + .switchSlider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded switchSliders */
    .switchSlider.round {
      border-radius: 34px;
    }

    .switchSlider.round:before {
      border-radius: 50%;
    }

  /* end rounded switch */

</style>
